<template>
    <delivery-note ref="editDeliveryNote" @refreshDecimals="setNumberOfDecimals"/>
</template>

<script>
    import DeliveryNote from './Components/DeliveryNote.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            DeliveryNote
        },
        data() {
            return {
                deliveryNote: null,
                deliveryNoteId: '',
                deliveryNoteTemporary: null,
                editing: false
            }
        },
        methods:{
            getNumOfDecimalPlaces(data) {
                const dataConverted = String(this.$numberRounding(data, 6))
                const dataLength = dataConverted.length
                let decimals = 0
                for (let i = 0; i <= 5; i++) {
                    if (dataConverted[dataLength - i - 1] === '0') {
                        decimals++
                    } else {
                        break
                    }
                }
                return 6 - decimals
            },
            async loadDataEditing() {
                try {
                    const res1 = this.$http.get(`/api/client/v1/delivery_notes_temporary/${this.deliveryNoteId}/`)
                    const res2 = this.$http.get(`/api/client/v1/delivery_notes/${this.deliveryNoteId}/`)
                    
                    const responses = await Promise.all([res1, res2])
                    this.deliveryNoteTemporary = responses[0].data ?? {}
                    this.editing = responses[1].data.exists ?? false
                    this.deliveryNote = responses[1].data.delivery_note ?? {}
                    
                    this.deliveryNote.timestamp = this.dayjs(this.deliveryNote.timestamp).toISOString()
                    this.deliveryNoteTemporary.timestamp = this.dayjs(this.deliveryNoteTemporary.timestamp).toISOString()

                    this.setNumberOfDecimals()
                } catch (error) {
                    Sentry.captureException(error)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            setNumberOfDecimals() {
                if (this.deliveryNote.items && this.deliveryNote.items.length > 0) {
                    const decimalPlaces1 = this.deliveryNote.items.map((item) => this.getNumOfDecimalPlaces(item.price_per_unit))
                    const decimalPlaces2 = this.deliveryNote.items.map((item) => this.getNumOfDecimalPlaces(item.price_with_discount_and_vat))
                    
                    this.deliveryNote.decimals = Math.max(...[...decimalPlaces1, ...decimalPlaces2])
                } else {
                    this.deliveryNote.decimals = 2
                }
            }
        },
        async mounted() {
            this.deliveryNoteId = this.$route.params.id
            await this.loadDataEditing()
            await this.$refs.editDeliveryNote.openEditing(this.deliveryNoteTemporary, this.deliveryNote, this.editing)
        }
    }
</script>