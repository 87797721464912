var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"addProductToDeliveryNote",attrs:{"title":_vm.$t('material.adding_ingredient'),"size":"lg"},on:{"ok":_vm.validate},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.hide}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addItem}},[(!_vm.editing)?_c('span',[_vm._v(_vm._s(_vm.$t('general.add')))]):_c('span',[_vm._v(_vm._s(_vm.$t('general.edit')))])])]},proxy:true}])},[_c('validation-observer',{ref:"validationAddItem"},[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('general.ingredient')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.ingredient'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"ingredient",staticClass:"ingredient",attrs:{"label":"name","filter":_vm.search,"options":_vm.ingredients,"disabled":_vm.editing,"clearable":false},on:{"option:selected":_vm.changeDefault},scopedSlots:_vm._u([{key:"option",fn:function(item){return [_vm._v(" "+_vm._s(item.ingredient_code)+" - "+_vm._s(item.name)+" ")]}},{key:"selected-option",fn:function(item){return [_vm._v(" "+_vm._s(item.ingredient_code)+" - "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.selectedIngredient),callback:function ($$v) {_vm.selectedIngredient=$$v},expression:"selectedIngredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.quantity'),"rules":"required|max_value:1000000|decimalCustom:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","max":"1000000"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.packaging')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.packaging'),"rules":_vm.getQuantityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient},model:{value:(_vm.packaging),callback:function ($$v) {_vm.packaging=_vm._n($$v)},expression:"packaging"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"unit-select",attrs:{"disabled":!_vm.selectedIngredient,"appendToBody":"","label":"name","options":_vm.types,"clearable":false},model:{value:(_vm.deliveryNoteItem.packaging_unit),callback:function ($$v) {_vm.$set(_vm.deliveryNoteItem, "packaging_unit", $$v)},expression:"deliveryNoteItem.packaging_unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.net_price_per_unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('material.delivery_notes.net_price_per_unit'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","min":"0"},model:{value:(_vm.price_per_unit),callback:function ($$v) {_vm.price_per_unit=_vm._n($$v)},expression:"price_per_unit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.gross_price_per_unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('material.delivery_notes.gross_price_per_unit'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","min":"0"},model:{value:(_vm.price_per_unit_bruto),callback:function ($$v) {_vm.price_per_unit_bruto=_vm._n($$v)},expression:"price_per_unit_bruto"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.vat_value_per_unit')}},[_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.vat_value_single),callback:function ($$v) {_vm.vat_value_single=$$v},expression:"vat_value_single"}})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.net_price_total')}},[_c('validation-provider',{attrs:{"name":_vm.$t('material.delivery_notes.net_price_total'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","min":"0"},model:{value:(_vm.total_price_neto),callback:function ($$v) {_vm.total_price_neto=_vm._n($$v)},expression:"total_price_neto"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.gross_price_total')}},[_c('validation-provider',{attrs:{"name":_vm.$t('material.delivery_notes.gross_price_total'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","min":"0"},model:{value:(_vm.total_price),callback:function ($$v) {_vm.total_price=_vm._n($$v)},expression:"total_price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.vat_value_total')}},[_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.vat_value),callback:function ($$v) {_vm.vat_value=$$v},expression:"vat_value"}})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.discount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.discount'),"rules":"required|min_value:0|max_value:100|decimalCustom:2|mustBePositive:true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"min":"0","max":"100","lazy":""},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=_vm._n($$v)},expression:"discount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.tax_rate')}},[_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.vat_percentage),callback:function ($$v) {_vm.vat_percentage=$$v},expression:"vat_percentage"}})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.warehouse')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.warehouse'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.warehouses,"disabled":!_vm.selectedIngredient,"clearable":false},on:{"input":_vm.loadData},model:{value:(_vm.warehouseData),callback:function ($$v) {_vm.warehouseData=$$v},expression:"warehouseData"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.selectedIngredient)?_c('b-form-group',{attrs:{"label":_vm.$t('material.delivery_notes.last_price_at_supplier')}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.selectedAveragePrice),callback:function ($$v) {_vm.selectedAveragePrice=$$v},expression:"selectedAveragePrice"}})],1):_vm._e()],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }